import React from 'react';
import '../App.css';
import { Marker } from 'busie-here-maps-react';
import Geocode from 'react-geocode';

import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';

export default function Markers(props) {

  const handleMarkerDrag = (i, target) => {
    const coords = {lat: target.getGeometry().lat, lng: target.getGeometry().lng}
    Geocode.fromLatLng(coords.lat, coords.lng)
    .then(res => {
        const address = res.results[0].formatted_address;
        const newPoint = {loc: address, visible: false, lat: coords.lat, lng: coords.lng, index: i}
        props.addDraggedPoint(newPoint);
      })
      .catch(error => {
        console.error(error);
      }
    );
  }


  React.useEffect(()=>{
      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_PLACES_API_KEY);
  }, [])


  const renderMarkers = (markers) => {
    return markers.map((marker, index) => {
      return (
        <Marker
          draggable
          lat={marker.lat}
          lng={marker.lng}
          key={"marker " + index}
          onDragEnd={(e) =>  handleMarkerDrag(index, e.target)}
        >
          <div style={{position: 'absolute', height: 20, width: 20, top: -10, left: -10}}>
            <FiberManualRecordRoundedIcon className="hover-marker" style={{fontSize: 20, color: '#d63031'}} />
          </div>
        </Marker>
      )
    })
  }

  const markers = props.markers;

  return(
    <div style={{zIndex: 990, position: 'absolute'}}>
        {renderMarkers(markers)}
    </div>
  )
}
