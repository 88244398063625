import React from 'react';
import './App.css';
import {connect} from 'react-redux'
import { setError, setErrorBool } from './actions';

import Header from './Components/Header';
import Auth from './Auth';
import Pathfinder from './Pathfinder';

class App extends React.Component {

  state = {
    authPath: 'welcome',
    loading: false,
    inviteClicked: false,
    operatorCode: null,
    loggingOut: false
  }

  changeAuthPath = path => {
    if (path === 'welcome') {
      this.setState({authPath: 'welcome'})
    } else if (path === 'login') {
      this.setState({authPath: 'login'})
    } else if (path === 'register') {
      this.setState({authPath: 'register'})
    } else {
      this.setState({authPath: 'done'})
    }
  }

  handleInviteClick = () => {
    this.setState({inviteClicked: !this.state.inviteClicked})
  }

  triggerClearPathfinderOnLogout = () => {
    this.setState({loggingOut: true})
  }

  handleBadRequest = (msg) => {
    this.props.setErrorBool()
    if (msg.errorType === '403 Forbidden:') {
      var message = {errorType: msg.errorType, errorMessage: ""}
      this.props.setError(message)
    } else {
      this.props.setError(msg)
    }
  }

  componentDidMount(){
    if (localStorage.token) {
      this.setState({authPath: 'done'})
    } else if (window.location.pathname.includes('/register')) {
      var operatorCode = window.location.search.split("=")[1]
      this.setState({operatorCode: operatorCode, authPath: 'register'})
    } else {
      this.setState({authPath: 'welcome'})
    }
  }

  render() {
    return (
      <div className="App">
        <Header changeAuthPath={this.changeAuthPath} authPath={this.state.authPath} handleInviteClick={this.handleInviteClick} loggingOut={this.state.loggingOut} triggerClearPathfinderOnLogout={this.triggerClearPathfinderOnLogout}/>
        {this.state.authPath !== 'done' ?
          <Auth authPath={this.state.authPath} changeAuthPath={this.changeAuthPath} operatorCode={this.state.operatorCode}/>
        :
        this.state.loading ?
          null
        :
          <Pathfinder
            inviteClicked={this.state.inviteClicked}
            handleInviteClick={this.handleInviteClick}
            loggingOut={this.state.loggingOut}
            handleBadRequest={this.handleBadRequest}
            error={this.props.errorObj}
            errorBool={this.props.errorBool}
          />
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    errorObj: state.errors.error,
    errorBool: state.errors.isError
  }
}

export default connect(mapStateToProps, {setError, setErrorBool})(App);
