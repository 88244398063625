import {
  SAVE_MARKERS,
  SAVE_SHAPE,
  SAVE_DIRECTIONS,
  SET_TOLL_COST,
  SET_STATES,
  SET_TOTAL_DISTANCE,
  SET_TRAVEL_TIME,
  SET_WAYPOINTS,
  SAVE_DATA,
  CLEAR_REDUCERS,
  RESTRICTED_CIRCLE,
  SET_ERROR,
  ERROR_BOOL
} from './types';


export function saveMarkers(event) {
  return {
    type: SAVE_MARKERS,
    payload: event
  }
}

export function saveShape(event) {
  return {
    type: SAVE_SHAPE,
    payload: event
  }
}

export function setSaveData(event) {
  return {
    type: SAVE_DATA,
    payload: event
  }
}

export function saveDirections(event) {
  return {
    type: SAVE_DIRECTIONS,
    payload: event
  }
}

export function setTollCost(event) {
  return {
    type: SET_TOLL_COST,
    payload: event
  }
}

export function setMileageByState(event) {
  return {
    type: SET_STATES,
    payload: event
  }
}

export function setTotalDistance(event) {
  return {
    type: SET_TOTAL_DISTANCE,
    payload: event
  }
}

export function setTravelTime(event) {
  return {
    type: SET_TRAVEL_TIME,
    payload: event
  }
}

export function setWaypoints(event) {
  return {
    type: SET_WAYPOINTS,
    payload: event
  }
}

export function clearReducers() {
  return {
    type: CLEAR_REDUCERS
  }
}

export function createCircle(event) {
  return {
    type: RESTRICTED_CIRCLE,
    payload: event
  }
}

export function setErrorBool() {
  return {
    type: ERROR_BOOL,
    payload: true
  }
}

export function setError(evnt) {
  return {
    type: SET_ERROR,
    payload: evnt
  }
}
