import React from 'react';
import './App.css';
import {connect} from 'react-redux'
import { clearReducers } from './actions';
import { getSavedRoutes } from './api';

import PathfinderMap from './Map/PathfinderMap';
import Modal from './Components/Modal';
import Snackbar from './Components/Snackbar';
import Directions from './Components/Directions';

class Pathfinder extends React.Component {

  state = {
    showModal: false,
    modalAction: null,
    apiKey: null,
    routeLoaded: false,
    loadedRoute: {},
    savedRoutes: [],
    showMap: true
  }

  startLoad = () => {
    this.setState({showModal: true, modalAction: 'loading', routeLoaded: false})
  }

  endLoad = (bool) => {
    this.setState({showModal: false, modalAction: null, routeLoaded: bool ? true : false})
  }

  openSaveModal = () => {
    this.setState({showModal: true, modalAction: 'save'})
  }

  openLoadModal = () => {
    const axios = require("axios");
    if (this.state.savedRoutes.length === 0){
      getSavedRoutes(axios, this.state.apiKey)
      .then(data => {
        data.forEach(route => {
          this.setState({savedRoutes: [...this.state.savedRoutes, {value: route.id, text: route.name}]})
          return
        })
      }).catch(msg => {
        this.props.handleBadRequest(msg)
      })
    }
    this.setState({showModal: true, modalAction: 'load'})
  }

  loadedRouteData = (data) => {
    this.setState({loadedRoute: data})
  }

  closeSaveModal = () => {
    this.setState({showModal: false, modalAction: null})
  }

  clearCurrentData = () => {
    this.setState({
      routeLoaded: false,
      loadedRoute: {}
    })
  }

  clearMap = () => {
    this.setState({showMap: false, routeLoaded: false, loadedRoute: {}})
    setTimeout(()=>this.setState({showMap: true}), 1000)
  }


  componentDidMount(){
    let apiKey = JSON.parse(localStorage.pathfinder_user).api_key;
    this.setState({ apiKey })
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.inviteClicked !== this.props.inviteClicked) {
      if (this.props.inviteClicked) {
        this.setState({showModal: true, modalAction: 'invite'})
      } else {
        this.setState({showModal: false, modalAction: null})
      }
    }
    if ((prevProps.errorBool !== this.props.errorBool) && this.props.errorBool) {
      this.endLoad(false)
    }
  }

  render() {
    return (
      <div className="Pathfinder-Container">
        <PathfinderMap
          apiKey={this.state.apiKey}
          startLoad={this.startLoad}
          endLoad={this.endLoad}
          routeLoaded={this.state.routeLoaded}
          openSaveModal={this.openSaveModal}
          openLoadModal={this.openLoadModal}
          loadedRoute={this.state.loadedRoute}
          clearCurrentData={this.clearCurrentData}
          shape={this.props.shape}
          clearMap={this.clearMap}
          showMap={this.state.showMap}
          wayPoints={this.props.wayPoints}
          loggingOut={this.props.handleLogout}
          handleBadRequest={this.props.handleBadRequest}
        />
        {this.state.showMap && this.props.instructions.length > 0 &&
          <Directions
            directions={this.props.instructions}
            tollCost={this.props.tollCost}
            milesByState={this.props.milesByState}
            totalDistance={this.props.totalDistance}
            travelTime={this.props.travelTime}
            wayPoints={this.props.wayPoints}
          />}
        {this.state.showModal &&
          <Modal
            action={this.state.modalAction}
            apiKey={this.state.apiKey}
            closeSaveModal={this.closeSaveModal}
            saveData={this.props.saveData}
            loadedRoute={this.state.loadedRoute}
            startLoad={this.startLoad}
            savedRoutes={this.state.savedRoutes}
            loadedRouteData={this.loadedRouteData}
            inviteClicked={this.props.inviteClicked}
            handleInviteClick={this.props.handleInviteClick}
            handleBadRequest={this.props.handleBadRequest}
          />
        }
        {this.props.errorBool &&
          <Snackbar
            error={this.props.error}
          />
        }
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    instructions: state.direction.directions,
    tollCost: state.direction.tollCost,
    milesByState: state.direction.milesByState,
    totalDistance: state.direction.totalDistance,
    travelTime: state.direction.travelTime,
    wayPoints: state.direction.wayPoints,
    saveData: state.route.saveData,
    shape: state.route.newRouteShape
  }
}

export default connect(mapStateToProps, {clearReducers})(Pathfinder);
