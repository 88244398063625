import React from 'react';
import '../App.css';
import { HEREMap, RouteLine, Circle } from 'busie-here-maps-react';
import { update, route, report } from '../api.js'
import {connect} from 'react-redux'
import Geocode from "react-geocode";
import {isMobile} from 'react-device-detect';

import {
  saveMarkers,
  saveShape,
  saveDirections,
  setTollCost,
  setMileageByState,
  setTotalDistance,
  setTravelTime,
  setWaypoints,
  setSaveData,
  clearReducers,
  createCircle
} from '../actions';

import RouteModalDnD from './RouteModalDnD';
import Markers from './Markers';
import StaticMarkers from './StaticMarkers';
import StopsMarkers from './StopsMarkers';

import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

class PathfinderMap extends React.Component {

  state = {
    points: {stops: [], draggedPoints: [], totalPoints: []},
    mapCenter: {lat: 40.730610, lng: -73.935242},
    mapZoom: 7,
    tollCost: null,
    markersQty: 1,
    state_mileage: "",
    routeId: null,
    circlePoints: [],
    squareCoords: [],
    restrictedAreaDirections: ["1) Be as specific as possible (zoom in!)", "2) Click map twice to define the center and radius of the circle", "3) Confirm that the boxed area is correct", "Click the ✓ to submit this restricted area", "Successfully reported restricted area"],
    directionIndex: 0,
    createRestriction: false,
    errorMessage: null,
    editingEnabled: false,
    mapInstance: null,
    renderStopsMarkers: false,
    waypointLength: 0,
    routeType: null
  }

  enableEditing = () => {
    this.setState({editingEnabled: !this.state.editingEnabled})
  }

  createRestrictionBool = () => {
    this.setState({
      createRestriction: !this.state.createRestriction
    })
  }

  addPointToRoute = (loc, lat, lng) => {
    if (loc.description){
      const obj = {loc: loc.description, lat: lat, lng: lng}
      this.setState({
        points: {stops: [...this.state.points.stops, obj], draggedPoints: this.state.points.draggedPoints, totalPoints: this.state.points.totalPoints}
      })
    } else {
      const obj = {loc: loc, lat: lat, lng: lng}
      this.setState({
        points: {stops: [...this.state.points.stops, obj], draggedPoints: this.state.points.draggedPoints, totalPoints: this.state.points.totalPoints}
      })
    }
  }

  addDraggedPoint = (payload) => {
    this.setState({
      points: {stops: this.state.points.stops, draggedPoints: [...this.state.points.draggedPoints, payload], totalPoints: this.state.points.totalPoints}
    })
  }

  sortTotalPoints = async(points) => {
    if (Object.keys(this.props.loadedRoute).length > 0) {
      await this.setState({
        points: {stops: points.stops, draggedPoints: points.draggedPoints, totalPoints: []}
      })
      const allPoints = points.stops.concat(points.draggedPoints);
      for (let i=0;i<allPoints.length;i++){
        if (allPoints[i].index) {
          let indexedObj = {loc: allPoints[i].loc, lat: allPoints[i].lat, lng: allPoints[i].lng, index: allPoints[i].index}
          this.setState({
            points: {
              stops: this.state.points.stops,
              draggedPoints: this.state.points.draggedPoints,
              totalPoints: [...this.state.points.totalPoints, indexedObj]
            }
          })
        } else {
          let j = this.props.markers.findIndex(point => (point.lat.toFixed(5) === allPoints[i].lat.toFixed(5)) && (point.lng.toFixed(5) === allPoints[i].lng.toFixed(5)));
          if (j !== -1){
            let indexedObj = {loc: allPoints[i].loc, lat: allPoints[i].lat, lng: allPoints[i].lng, index: j}
            this.setState({
              points: {
                stops: this.state.points.stops,
                draggedPoints: this.state.points.draggedPoints,
                totalPoints: [...this.state.points.totalPoints, indexedObj]
              }
            })
          }
        }
      }
    } else {
      await this.setState({
        points: {stops: points.stops, draggedPoints: points.draggedPoints, totalPoints: []}
      })
      const allPoints = this.props.wayPoints.concat(points.draggedPoints);
      for (let i=0;i<allPoints.length;i++){
        if (allPoints[i].index) {
          let indexedObj = {loc: allPoints[i].loc, lat: allPoints[i].lat, lng: allPoints[i].lng, index: allPoints[i].index}
          this.setState({
            points: {
              stops: this.state.points.stops,
              draggedPoints: this.state.points.draggedPoints,
              totalPoints: [...this.state.points.totalPoints, indexedObj]
            }
          })
        } else {
          let j = this.props.markers.findIndex(point => (point.lat.toFixed(5) === allPoints[i].mappedPosition.latitude.toFixed(5)) && (point.lng.toFixed(5) === allPoints[i].mappedPosition.longitude.toFixed(5)));
          if (j !== -1) {
            Geocode.fromLatLng(`${allPoints[i].mappedPosition.latitude}`, `${allPoints[i].mappedPosition.longitude}`)
            .then(res => {
              const address = {loc: res.results[0].formatted_address, lat: allPoints[i].mappedPosition.latitude, lng: allPoints[i].mappedPosition.longitude, index: j};
              this.setState({
                points: {
                  stops: this.state.points.stops,
                  draggedPoints: this.state.points.draggedPoints,
                  totalPoints: [...this.state.points.totalPoints, address]
                }
              })
            })
          }
        }
      }
    }
  }

  comparePointsIndex = (a,b) => {
    let comparison = 0;
    if (a.index > b.index) {
      comparison = 1
    } else if (a.index < b.index) {
      comparison = -1
    }
    return comparison;
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  removeStop = (ind) => {
    const items = Array.from(this.state.points.stops);
    const totalItems = Array.from(this.state.points.totalPoints);
    items.splice(ind, 1);
    totalItems.splice(ind, 1);
    this.setState({
      points: {stops: items, draggedPoints: this.state.points.draggedPoints, totalPoints: totalItems}
    })
  }

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = this.reorder(this.state.points.stops, result.source.index, result.destination.index);
    this.setState({
      points: {stops: items, draggedPoints: this.state.points.draggedPoints, totalPoints: this.state.points.totalPoints}
    })
  }

  moveStopUp = (ind) => {
    const items = this.reorder(this.state.points.stops, ind, ++ind)
    this.setState({
      points: {stops: items, draggedPoints: this.state.points.draggedPoints, totalPoints: this.state.points.totalPoints}
    })
  }

  moveStopDown = (ind) => {
    const items = this.reorder(this.state.points.stops, ind, --ind)
    this.setState({
      points: {stops: items, draggedPoints: this.state.points.draggedPoints, totalPoints: this.state.points.totalPoints}
    })
  }

  createPayload = (stops, roundTrip) => {
    var payload = {},
        arr = [];
    if (roundTrip) {
      arr = [...stops, stops.slice(0,1)[0]]
      this.setState({
        points: {stops: this.state.points.stops, draggedPoints: this.state.points.draggedPoints, totalPoints: arr}
      })
    } else {
      arr = stops
    }
    if (arr.length > 2) {
      let add = [];
      arr.slice(1, arr.length-1).forEach(item => {
        add = [...add, item.loc]
        return
      });
      payload = {
        api_key: this.props.apiKey,
        start: arr.slice(0,1)[0].loc,
        dest: arr.slice(-1)[0].loc,
        additional: JSON.stringify(add)
      }
    } else {
      payload = {
        api_key: this.props.apiKey,
        start: arr.slice(0,1)[0].loc,
        dest: arr.slice(-1)[0].loc,
        additional: "[]"
      }
    }
    return payload;
  }

  addLoadedWaypointsToState = async(arr) => {
    for (let i=0;i<arr.length;i++) {
      Geocode.fromLatLng(`${arr[i].mappedPosition.latitude}`, `${arr[i].mappedPosition.longitude}`)
      .then(res => {
          const address = {loc: res.results[0].formatted_address, lat: arr[i].mappedPosition.latitude, lng: arr[i].mappedPosition.longitude};
          this.setState({
            points: {stops: [...this.state.points.stops, address], draggedPoints: this.state.points.draggedPoints, totalPoints: this.state.points.totalPoints}
          })
        })
        .catch(error => {
          console.error(error);
        }
      )
    }
  }

  parseRoute = (data, type) => {
    this.props.setTollCost(data.toll_cost)
    this.setState({state_mileage: JSON.stringify(data.states), routeId: data.id})
    this.getMilesByState(data.states ? data.states : data.state_mileage);
    var route = data.route;
    var routeString = "";
    for (var i=0; i<route.length; i++) {
      routeString = routeString + route[i];
    }
    if (route.length>0) {
      var parsedRoute = JSON.parse(routeString)
      this.parseSummary(parsedRoute.response.route[0].summary)
      this.props.setWaypoints(parsedRoute.response.route[0].waypoint)
      this.createRoute(parsedRoute.response.route[0], type);
      this.addMarkers(parsedRoute.response.route[0]);
      this.setState({renderStopsMarkers: true});
      if (type === 'load') {
        this.addLoadedWaypointsToState(parsedRoute.response.route[0].waypoint)
        this.setState({waypointLength: parsedRoute.response.route[0].waypoint.length, routeType: 'load'})
      }
    }
  }

  parseSummary = (summary) => {
    const totDistance = Math.round(summary.distance * 0.000621371);
    const totTime = Math.ceil(summary.travelTime/60);
    this.props.setTotalDistance(totDistance);
    this.props.setTravelTime(totTime)
  }

  getMilesByState = (states) => {
    var arr = []
    for (var key in states) {
     if (states.hasOwnProperty(key)) {
       let item = {state: key, distance: Math.round(states[key] * 0.000621371)}
       arr = [...arr, item]
     }
   }
   this.props.setMileageByState(arr);
  }

  addMarkers = (route) => {
    var maneuver,
      markers = [],
      instructions = [];
    for (var i=0;  i < route.leg.length; i++) {
      for (var j=0;  j < route.leg[i].maneuver.length; j++) {
        this.setState({markersQty: this.state.markersQty+1})
        // Get the next maneuver.
        maneuver = route.leg[i].maneuver[j];
        // Add a marker to the maneuvers group
        var marker = {
          lat: maneuver.position.latitude,
          lng: maneuver.position.longitude
        }
        instructions = [...instructions, maneuver.instruction]
        markers = [...markers, marker]
      }
    }
    this.setState({markersQty: this.state.markersQty-1})
    this.props.saveDirections(instructions)
    this.props.saveMarkers(markers)
    this.props.endLoad(true);
  }

  createRoute = (route, type) => {
    var lineString = [],
        maxLat = 0,
        minLat = 0,
        maxLng = 0,
        minLng = 0,
        routeShape = route.shape;
    try {
      routeShape.forEach(point => {
        var parts = point.split(',');
        lineString = [...lineString, parts[0], parts[1] ]
      });
    } catch (e) {
      if (e.name === 'TypeError') {
        for (var i=0; i<(routeShape.length/2); i++) {
          var j = (2 * i);
          maxLat = i === 0 ? routeShape[j] : routeShape[j] >= maxLat ? routeShape[j] : maxLat;
          minLat = i === 0 ? routeShape[j] : routeShape[j] <= minLat ? routeShape[j] : minLat;
          maxLng = i === 0 ? routeShape[j+1] : routeShape[j+1] >= maxLng ? routeShape[j+1] : maxLng;
          minLng = i === 0 ? routeShape[j+1] : routeShape[j+1] <= minLng ? routeShape[j+1] : minLng;
          lineString = [...lineString, routeShape[j] + ',' + routeShape[j+1] ]
        }
      }
    }
    if (type !== 'edit') {
      this.resizeMap(maxLat, maxLng, minLat, minLng);
      this.props.saveShape(lineString)
    } else {
      this.props.saveShape(lineString)
    }
  }

  handleBadRequestAndStopLoad = msg => {
    this.props.handleBadRequest(msg);
    this.props.endLoad(false)
  }

  handleNewRoute = async(stops, roundTrip, type) => {
    const axios = require('axios');
    this.props.clearReducers();
    this.props.clearMap();
    this.props.startLoad();
    this.setState({editingEnabled: false, markersQty: 1, points: {stops: this.state.points.stops, draggedPoints: [], totalPoints: this.state.points.totalPoints}})
    var data = {'api_key': this.props.apiKey}
    update(data, axios, this.props.apiKey)
    if (type === 'new') {
      const payload = this.createPayload(stops.stops, roundTrip)
      route(payload, axios).then((data) => {
        this.parseRoute(data, 'new')
      }).catch(msg => {
        this.handleBadRequestAndStopLoad(msg)
        this.setState({errorMessage: msg})
      })
    } else if (type === 'edit') {
      if (stops.draggedPoints.length > 0) {
        const payload = this.createPayload(stops.totalPoints, roundTrip)
        route(payload, axios).then((data) => {
          this.parseRoute(data, 'edit')
        }).catch(msg => {
          this.handleBadRequestAndStopLoad(msg)
          this.setState({errorMessage: msg})
        })
      } else {
        const payload = this.createPayload(stops.stops, roundTrip)
        route(payload, axios).then((data) => {
          this.parseRoute(data, 'edit')
        }).catch(msg => {
          this.handleBadRequestAndStopLoad(msg)
          this.setState({errorMessage: msg})
        })
      }
    }
  }

  resizeMap = (maxLat, maxLng, minLat, minLng) => {
    var lat = (maxLat + minLat)/2;
    var lng = (maxLng + minLng)/2;
    this.getBoundsZoomLevel(maxLat, maxLng, minLat, minLng)
    this.setState({mapCenter: {lat: lat, lng: lng}})
  }

  getBoundsZoomLevel = (maxLat, maxLng, minLat, minLng) => {
      var WORLD_DIM = { height: 256, width: 256 },
          ZOOM_MAX = 21,
          MAP_HEIGHT = window.innerHeight*0.75,
          MAP_WIDTH = window.innerWidth*0.875;

      function latRad(lat) {
          var sin = Math.sin(lat * Math.PI / 180);
          var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
          return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
      }

      function zoom(mapPx, worldPx, fraction) {
          return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
      }

      var ne = {lat: maxLat, lng: maxLng}
      var sw = {lat: minLat, lng: minLng}

      var latFraction = (latRad(ne.lat) - latRad(sw.lat)) / Math.PI;

      var lngDiff = ne.lng - sw.lng;
      var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

      var latZoom = zoom(MAP_HEIGHT, WORLD_DIM.height, latFraction);
      var lngZoom = zoom(MAP_WIDTH, WORLD_DIM.width, lngFraction);
      this.setState({mapZoom: Math.min(latZoom, lngZoom, ZOOM_MAX)})
  }

  componentDidUpdate = async(prevProps, prevState) => {
    if (Object.keys(this.props.loadedRoute).length > 0) {
      if (prevProps.loadedRoute !== this.props.loadedRoute) {
        var data = this.props.loadedRoute;
        var states_mileage = await JSON.parse(data.state_mileage);
        data.state_mileage = states_mileage;
        this.parseRoute(data, 'load')
      }
    }
    if (prevState.points.draggedPoints !== this.state.points.draggedPoints) {
      this.sortTotalPoints(this.state.points)
    }
    if (prevState.points.totalPoints.length !== this.state.points.totalPoints.length && this.state.points.totalPoints.length > 0) {
      if (this.state.points.totalPoints[0].index === 0) {
        const sortedPoints = this.state.points.totalPoints.sort(this.comparePointsIndex);
        this.setState({
          points: {
            stops: this.state.points.stops,
            draggedPoints: this.state.points.draggedPoints,
            totalPoints: sortedPoints
          }
        })
      }
    }
    if (prevProps.loggingOut !== this.props.loggingOut && this.props.loggingOut === true) {
      this.clearPathfinder()
    }
  }

  distanceFormula = (lat1, lat2, lon1, lon2) => {
    var r = 6371 * Math.exp(3),
        φ1 = lat1 * Math.PI/180,
        φ2 = lat2 * Math.PI/180,
        Δφ = (lat2-lat1) * Math.PI/180,
        Δλ = (lon2-lon1) * Math.PI/180,
        a = Math.sin(Δφ/2) * Math.sin(Δφ/2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ/2) * Math.sin(Δλ/2),
        c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    return (r * c) * 50;
  };

  getInscribedSquare = (lat, lng, distance) => {
    var ne,
        nw,
        se,
        sw,
        r = 6371 * Math.exp(3),
        newLat,
        newLng;
    const dist = ((distance/50)*Math.sqrt(2)/2)
    for (let i=0;i<4;i++) {
      if (i===0) {
        newLat = dist/r;
        newLng = dist/(r*Math.cos(Math.PI*lat/180))
        ne = {lat: (lat + newLat * 180/Math.PI), lng: (lng - newLng * 180/Math.PI)}
        this.setState({squareCoords: [...this.state.squareCoords, ne]})
      } else if (i===1) {
        newLat = dist/r;
        newLng = dist/(r*Math.cos(Math.PI*lat/180))
        nw = {lat: (lat + newLat * 180/Math.PI), lng: (lng + newLng * 180/Math.PI)}
        this.setState({squareCoords: [...this.state.squareCoords, nw]})
      } else if (i===2) {
        newLat = dist/r;
        newLng = dist/(r*Math.cos(Math.PI*lat/180))
        se = {lat: (lat - newLat * 180/Math.PI), lng: (lng - newLng * 180/Math.PI)}
        this.setState({squareCoords: [...this.state.squareCoords, se]})
      } else if (i===3) {
        newLat = dist/r;
        newLng = dist/(r*Math.cos(Math.PI*lat/180))
        sw = {lat: (lat - newLat * 180/Math.PI), lng: (lng + newLng * 180/Math.PI)}
        this.setState({squareCoords: [...this.state.squareCoords, sw]})
      }
    }
  }

  handleMapTaps = (event, map) => {
    const coords = map.screenToGeo(
      event.currentPointer.viewportX,
      event.currentPointer.viewportY,
    );
    if (this.state.createRestriction) {
      if (this.state.circlePoints.length === 0) {
        this.setState({circlePoints: [...this.state.circlePoints, {lat: coords.lat, lng: coords.lng}]})
      } else if (this.state.circlePoints.length === 1) {
        this.setState({circlePoints: [...this.state.circlePoints, {lat: coords.lat, lng: coords.lng}]})
        const dist = this.distanceFormula(this.state.circlePoints[0].lat, this.state.circlePoints[1].lat, this.state.circlePoints[0].lng, this.state.circlePoints[1].lng)
        this.getInscribedSquare(this.state.circlePoints[0].lat, this.state.circlePoints[0].lng, dist);
        const completedCircle = {
          center: this.state.circlePoints[0],
          radius: Math.ceil(dist)
        }
        this.props.createCircle(completedCircle)
      }
    }
  }

  closeAndDeleteRestriction = () => {
    const noCircle = {
      center: {lat: 0, lng: 0},
      radius: 0
    }
    this.props.startLoad();
    this.props.clearMap();
    this.setState({createRestriction: false, squareCoords: [], circlePoints: []})
    this.props.createCircle(noCircle)
    setTimeout(()=>this.props.endLoad(false),1000)
  }

  submitRestrictedArea = () => {
    const axios = require('axios');
    const data = {
      api_key: this.props.apiKey,
      bbox: `${this.state.squareCoords[0].lat},${this.state.squareCoords[0].lng};${this.state.squareCoords[3].lat},${this.state.squareCoords[3].lng}`
    }
    report(data, axios)
    .then(()=>{
      this.setState({directionIndex: 4})
      setTimeout(()=>this.setState({
        directionIndex: 0,
        squareCoords: [],
        circlePoints: [],
        createRestriction: false,
      }), 4000)
    }).catch(msg => {
      this.handleBadRequestAndStopLoad(msg)
      this.setState({errorMessage: msg})
    })
  }

  clearPathfinder = () => {
    this.props.startLoad();
    this.props.clearReducers();
    this.props.clearMap();
    this.setState({
      points: {stops: [], draggedPoints: [], totalPoints: []},
      tollCost: null,
      mapZoom: 7,
      markersQty: 1,
      state_mileage: "",
      routeId: null,
      circlePoints: [],
      squareCoords: [],
      restrictedAreaDirections: ["1) Be as specific as possible (zoom in!)", "2) Click map twice to define the center and radius of the circle", "3) Confirm that the boxed area is correct", "Click the ✓ to submit this restricted area", "Successfully reported restricted area"],
      directionIndex: 0,
      createRestriction: false,
      errorMessage: null,
      editingEnabled: false,
      routeType: null
    })
    setTimeout(()=>this.props.endLoad(false), 1500)
  }

  handleMapZoom = map => {
    if (map.c.j){
      const currentZoom = map.getZoom();
      this.setState({mapZoom: currentZoom});
    }
  }

  setZoomAndCenter = map => {
    const currentZoom = map.getZoom();
    const currentCenter = map.getCenter();
    const formattedCenter = {
      lat: currentCenter.lat,
      lng: currentCenter.lng,
    };
    this.setState({mapZoom: currentZoom, mapCenter: formattedCenter});
  }

  nullFunction = () => {
    return
  }

  handlePointerEnter = map => {
    this.setState({mapInstance: map})
    map.addEventListener('mapviewchangeend', ()=>this.setZoomAndCenter(map))
  }

  handlePointerLeave = map => {
    map.removeEventListener('mapviewchangeend', this.nullFunction);
  }

  componentDidMount(){
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_PLACES_API_KEY);
  }

  componentDidUpdate = async(prevProps, prevState) => {
    if (Object.keys(this.props.loadedRoute).length > 0) {
      if (prevProps.loadedRoute !== this.props.loadedRoute) {
        var data = this.props.loadedRoute;
        var states_mileage = await JSON.parse(data.state_mileage);
        data.state_mileage = states_mileage;
        this.parseRoute(data, 'load')
      }
    }
    if (prevState.points.draggedPoints !== this.state.points.draggedPoints) {
      this.sortTotalPoints(this.state.points)
    }
    if (prevState.points.totalPoints.length !== this.state.points.totalPoints.length && this.state.points.totalPoints.length > 0) {
      if (this.state.points.totalPoints[0].index === 0) {
        const sortedPoints = this.state.points.totalPoints.sort(this.comparePointsIndex);
        this.setState({
          points: {
            stops: this.state.points.stops,
            draggedPoints: this.state.points.draggedPoints,
            totalPoints: sortedPoints
          }
        })
      }
    }
    if (prevProps.loggingOut !== this.props.loggingOut && this.props.loggingOut === true) {
      this.clearPathfinder()
    }
  }


  render() {
    this.state.points.totalPoints.length > 0 &&
      this.state.points.totalPoints[0].index &&
        this.state.points.totalPoints.sort(this.comparePointsIndex);
    return (
      this.props.showMap ?
      <div className="Map-Container">
        <HEREMap
          appId={process.env.REACT_APP_HERE_MAPS_APP_ID}
          appCode={process.env.REACT_APP_HERE_MAPS_APP_CODE}
          center={this.state.mapCenter}
          zoom={this.state.mapZoom}
          onTap={this.state.createRestriction ? (e)=>this.handleMapTaps(e, e.target) : null}
          onPointerEnter={(e)=>this.handlePointerEnter(e.target)}
          onPointerLeave={(e)=>this.handlePointerLeave(e.target)}
          secure
          interactive
        >
          {this.props.shape.length > 0 &&
            <RouteLine shape={this.props.shape} strokeColor="#d63031" lineWidth={3} />
          }
          {this.state.routeType === 'load' &&
            this.state.markersQty === this.props.markers.length &&
            this.state.waypointLength > 0 &&
            this.state.waypointLength === this.state.points.stops.length ?
            <StopsMarkers
              points={this.state.points}
            />
          :
            this.state.routeType === null &&
            this.state.markersQty === this.props.markers.length &&
            <StopsMarkers
              points={this.state.points}
            />
          }
          {this.state.markersQty === this.props.markers.length &&
            this.state.editingEnabled ?
            <Markers
              markers={this.props.markers}
              addDraggedPoint={this.addDraggedPoint}
            />
          :
            <StaticMarkers
              markers={this.props.markers}
            />
          }
          {this.state.circlePoints.length === 2 &&
            <Circle
              radius={this.props.restrictedCircle.radius}
              lat={this.props.restrictedCircle.center.lat}
              lng={this.props.restrictedCircle.center.lng}
              strokeColor="#d63031"
              fillColor="rgba(255, 118, 117,0.75)"
              lineWidth={2}
            />
          }
          {this.state.createRestriction &&
            <div style={{position: 'absolute', top: '17.5vh', right: '10vw', height: 50, width: 400, backgroundColor: this.state.directionIndex === 4 ? '#00b894' : '#2d3436', display: 'flex', flexDirection: 'row', justifyContent: this.state.directionIndex === 4 ? 'center' : 'space-between', alignItems: 'center', padding: 5}}>
              <p style={{color: 'white'}}>{
                this.state.errorMessage ?
                  this.state.errorMessage
                :
                  this.state.restrictedAreaDirections[this.state.directionIndex]
              }</p>
              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 50, alignItems: 'center'}}>
                {this.state.directionIndex !== 4 && <CloseRoundedIcon className="hover-small" style={{fontSize: 20, color: '#d63031'}} onClick={this.closeAndDeleteRestriction}/>}
                {this.state.directionIndex !== 4 && <CheckRoundedIcon className="hover-small" style={{fontSize: 30, color: '#00b894'}} onClick={this.state.directionIndex === 3 ? this.submitRestrictedArea : ()=>this.setState({directionIndex: this.state.directionIndex+1})}/>}
              </div>
            </div>
          }
          <div style={{position: isMobile && 'absolute', top: isMobile && '20%', left: isMobile && 0}}>
            <RouteModalDnD
              handleNewRoute={this.handleNewRoute}
              points={this.state.points}
              apiKey={this.props.apiKey}
              addPointToRoute={this.addPointToRoute}
              routeLoaded={this.props.routeLoaded}
              tollCost={this.props.tollCost}
              state_mileage={this.state.state_mileage}
              routeId={this.state.routeId}
              setSaveData={this.props.setSaveData}
              openSaveModal={this.props.openSaveModal}
              openLoadModal={this.props.openLoadModal}
              removeStop={this.removeStop}
              onDragEnd={this.onDragEnd}
              moveStopUp={this.moveStopUp}
              moveStopDown={this.moveStopDown}
              shape={this.props.shape}
              createRestrictionBool={this.createRestrictionBool}
              enableEditing={this.enableEditing}
              editingEnabled={this.state.editingEnabled}
              clearPathfinder={this.clearPathfinder}
            />
          </div>
        </HEREMap>
      </div>
    :
      <div className="Map-Container">
        <div style={{height: '100%', width: '100%', backgroundColor: '#dfe6e9', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <img src="https://s3.amazonaws.com/pathfinderclient/pathfinderlogo.png" alt="pathfinder logo" style={{width: '85%'}} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    markers: state.route.newRouteMarkers,
    instructions: state.direction.directions,
    tollCost: state.direction.tollCost,
    milesByState: state.direction.milesByState,
    restrictedCircle: state.circle.restrictedCircle,
    resPoints: state.circle.restrictionPoints
  }
}

export default connect(mapStateToProps, { saveMarkers, saveShape, saveDirections, setTollCost, setMileageByState, setTotalDistance, setTravelTime, setWaypoints, setSaveData, clearReducers, createCircle })(PathfinderMap);
