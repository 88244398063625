/*jshint esversion: 6*/
/*globals localStorage*/


export default {
  login(username, password, cb) {
    cb = arguments[arguments.length - 1];
    if (localStorage.token) {
      if(cb) cb(true);
      this.onChange(true);
      return;
    }
    var loginData = {username: username, password: password};
    loginRequest(loginData, (res) => {
      if (res.authenticated) {
        localStorage.token = res.token;
        localStorage.pathfinder_user = JSON.stringify(res.response.data);
        if (cb) cb(true);
        this.onChange(true);
      } else {
        if (cb) cb(false, res.message);
        this.onChange(false);
      }
    });
  },
  getToken() {
    return localStorage.token;
  },
  logout(cb) {
    delete localStorage.token;
    delete localStorage.pathfinder_user;
    if (cb) cb();
    this.onChange(false);
    logoutRequest();
  },
  loggedIn() {
    return !!localStorage.token;
  },
  onChange() {},
  register(data, cb) {
    cb = arguments[arguments.length - 1];
    registerRequest(data, (res) => {
      if (res.registered) {
        if (cb) cb(true);
      } else {
        if (cb) cb(false, res.message);
      }
    });
  },
};


function registerRequest(data, cb) {
  const axios = require('axios');
  setTimeout(() => {
    var url = `${process.env.REACT_APP_BUSIE_API_URL}/api/users/`,
    message;
    axios.post(url, data).then(function() {
      cb({registered: true});
    }).catch(function(error) {
      if (error.response) {
        message = error.response.data;
      } else if (error.request) {
        message = error.request.data;
      } else {
        message = error.message;
      }
      cb({registered: false, message: message});
    });
  }, 0);
}

function loginRequest(data, cb) {
  const axios = require('axios');
  setTimeout(() => {
    var url = `${process.env.REACT_APP_BUSIE_API_URL}/api/login/`,
    message;
    axios.post(url, data).then(function(response) {
      cb({
        authenticated: true,
        token: Math.random().toString(36).substring(7),
        response: response
      });
    }).catch(function(error){
      if (error.response) {
        message = error.response.data;
      } else if (error.request) {
        message = error.request.data;
      } else {
        message = error.message;
      }
      cb({authenticated: false, message: message});
    });
  }, 0);
}

function logoutRequest() {
  const axios = require('axios');
  setTimeout(() => {
    var url = `${process.env.REACT_APP_BUSIE_API_URL}/api/logout/`;
    axios.post(url).then(function() {
      return;
    }).catch(function(error){
      alert(`Unable to log out, please contact support: ${error}`);
    });
  }, 0);
}
