import React from 'react';
import '../App.css';
import {isMobile} from 'react-device-detect';

import auth from '../functions/auth';

function Header(props) {

  const handleLogout = () => {
    props.triggerClearPathfinderOnLogout();
    setTimeout(logout, 500);
  }

  const logout = function() {
    auth.logout();
    props.changeAuthPath('welcome');
    window.location.reload();
  }

  return(
    <div className="Header">
      <div>
        <img src="https://s3.amazonaws.com/pathfinderclient/pathfinderlogo.png" alt="pathfinderlogo"/>
      </div>
      {props.authPath !== 'done' ?
        <div className="Header-Options">
          <div className="link-text" onClick={()=>props.changeAuthPath('login')}>
            <h3 className="link-text" id="link-text-test" style={{textAlign: 'center', marginLeft: isMobile && -15}}>LOG IN</h3>
          </div>
          <a href="https://www.getbusie.com" target="_blank" rel="noopener noreferrer" className="link-text"><h3 className="link-text" style={{marginLeft: isMobile && 15}}>VISIT OUR SITE</h3></a>
        </div>
      :
        <div className="Header-Options">
          <div className="link-text" onClick={props.handleInviteClick}>
            <h3 className="link-text">INVITE USER</h3>
          </div>
          <div className="link-text" onClick={handleLogout}>
            <h3 className="link-text">LOG OUT</h3>
          </div>
        </div>
      }
    </div>
  )
}

export default Header;
