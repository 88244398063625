import React from 'react';
import '../App.css';

function Snackbar(props) {

  return (
    <div className="snackbar slide-down-snack">
      <div style={{height: 55, width: '100%', paddingLeft: 3, paddingRight: 3, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden'}}>
        <p style={{color: 'white', fontSize: 16}}>{props.error.errorType} {props.error.errorMessage}</p>
      </div>
      <div
        className="hover"
        style={{position: 'absolute', bottom: 5, width: 80, height: 30, backgroundColor: '#dfe6e9', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        onClick={()=>window.location.reload()}
      >
        <p style={{fontSize: 14}}>OK</p>
      </div>
    </div>
  )
}

export default Snackbar;
