import React from 'react';
import './App.css';
import {isMobile} from 'react-device-detect';

import PersonOutlineRoundedIcon from '@material-ui/icons/PersonOutlineRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import auth from './functions/auth';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#d63031',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#d63031',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#d63031',
      },
    },
  },
})(TextField);

export default class Auth extends React.Component {
  state = {
    usernameInput: "",
    passwordInput: "",
    newUsernameInput: "",
    newName: "",
    newLastName: "",
    newPasswordInput: "",
    passwordConfirmation: "",
    operatorCode: "",
    inputSelection: 'none',
    slideOut: false,
    slideIn: false,
    errorMsg: []
  }

  goToLogin = () => {
    this.setState({slideOut: true})
    setTimeout(()=>this.props.changeAuthPath('login'), 500)
    setTimeout(()=>this.setState({slideIn: true}), 500)
    setTimeout(()=>this.setState({slideIn: false, slideOut: false}), 1000)
  }

  goToWelcome = () => {
    this.setState({slideOut: true})
    setTimeout(()=>this.props.changeAuthPath('welcome'), 500)
    setTimeout(()=>this.setState({slideIn: true}), 500)
    setTimeout(()=>this.setState({slideIn: false, slideOut: false}), 1000)
  }

  goToRegister = () => {
    this.setState({slideOut: true})
    setTimeout(()=>this.props.changeAuthPath('register'), 500)
    setTimeout(()=>this.setState({slideIn: true}), 500)
    setTimeout(()=>this.setState({slideIn: false, slideOut: false}), 1000)
  }

  navigateToLogin = () => {
    this.setState({slideOut: true})
    setTimeout(()=>this.props.changeAuthPath('done'), 500)
    setTimeout(()=>this.setState({slideOut: false}), 500)
  }

  login = () => {
    auth.login(this.state.usernameInput, this.state.passwordInput, (loggedIn, ...args) => {
      if (!loggedIn) {
        this.alertError(args)
      } else {
        this.navigateToLogin()
      }
    })
  }

  register = () => {
    const payload = {
      user: {
        username: this.state.newUsernameInput,
        first_name: this.state.newName,
        last_name: this.state.newLastName,
        password: this.state.newPasswordInput
      },
      operator_key: this.state.operatorCode,
    }
    if (this.state.newPasswordInput !== this.state.passwordConfirmation) {
      this.snackbarText = 'Passwords Must Match';
      return;
    }
    auth.register(payload, (registered, ...args) => {
      if(!registered){
        this.alertError(args)
      } else {
        auth.login(payload.user.username, payload.user.password, (loggedIn, ...args) => {
          if(!loggedIn) {
            this.alertError(args)
          } else {
            this.navigateToLogin()
          }
        })
      }
    })
  }

  alertError = (args) => {
    if (typeof(args[0]) === 'object') {
      var errors = Object.keys(args[0]);
      for (var i = 0; i < errors.length; i++) {
        var error = `${errors[i]}: ${args[0][errors[i]]}`;
        this.setState({errorMsg: [...this.state.errorMsg, error]})
      }
      if (this.state.errorMsg.length > 1) {
        this.setState({errorMsg: ['Cannot submit a blank login form']})
      } else {
        if (this.state.errorMsg[0].includes('password')) {
          this.setState({errorMsg: ['Password field cannot be blank']})
        } else if (this.state.errorMsg[0].includes('username')) {
          this.setState({errorMsg: ['Username field cannot be blank']})
        } else if (this.state.errorMsg[0].includes('non_field_errors')) {
          this.setState({errorMsg: ['Unable to login with the given credentials']})
        }
      }
    } else {
      this.setState({errorMsg: [`${args}`]})
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.operatorCode !== this.props.operatorCode) {
      if (this.props.operatorCode !== null) {
        this.setState({operatorCode: this.props.operatorCode})
      }
    }
  }

  render(){
    if (this.props.authPath === 'welcome' && !isMobile) {
      return(
        <div className={`Welcome ${this.state.slideOut ? 'slide-out' : null} ${this.state.slideIn ? 'slide-in' : null}`}>
          <div className="title">
            <h2 style={{color: '#F2F2F2', marginLeft: '2%'}}>Welcome</h2>
          </div>
          <div style={{display: 'flex', justifyContent: 'center', alginItems: 'center', width: '100%', transition: 'width 1s'}}>
            <img style={{width: '50%'}} src="https://s3.amazonaws.com/pathfinderclient/pathfinderlogo.png" alt="pathfinderlogo"/>
          </div>
          <div className="two-button-container">
            <div className="button" onClick={this.goToRegister}>
              <h4 style={{color: 'white'}}>REGISTER</h4>
            </div>
            <div className="button" onClick={this.goToLogin}>
              <h4 style={{color: '#F2F2F2'}}>LOG IN</h4>
            </div>
          </div>
        </div>
      )
    } else if (this.props.authPath === 'welcome' && isMobile) {
      return(
        <div className={`Welcome ${this.state.slideOut ? 'slide-out' : null} ${this.state.slideIn ? 'slide-in' : null}`} style={{width: '90%'}}>
          <div className="title" style={{justifyContent: 'center'}}>
            <h2 style={{color: '#F2F2F2'}}>Welcome</h2>
          </div>
          <div className="two-button-container" style={{flexDirection: 'column', height: '90%', justifyContent: 'space-around'}}>
            <div className="button" onClick={this.goToRegister}>
              <h4 style={{color: 'white'}}>REGISTER</h4>
            </div>
            <div className="button" onClick={this.goToLogin}>
              <h4 style={{color: '#F2F2F2'}}>LOG IN</h4>
            </div>
          </div>
        </div>
      )
    } else if (this.props.authPath === 'login') {
      return(
        <div className={`Welcome ${this.state.slideOut ? 'slide-out' : null} ${this.state.slideIn ? 'slide-in' : null}`} style={{width: isMobile && '90%'}}>
          <div className="title">
            <h2 style={{color: '#F2F2F2', marginLeft: '2%'}}>Log In</h2>
          </div>
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alginItems: 'center', width: '100%', height: '50%'}}>
            <div stlye={{width: '96%', height: '45%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
              {!isMobile && <PersonOutlineRoundedIcon style={{fontSize: 50, color: this.state.inputSelection === 'username' ? '#d63031' : '#2d3436' }}/>}
              <CssTextField
                id="custom-css-standard-input username"
                label="Username"
                style={{width: '85%', marginLeft: !isMobile ? '2%' : '7.5%'}}
                onChange={(e)=>this.setState({usernameInput: e.target.value})}
                onFocus={()=>this.setState({inputSelection: 'username'})}
                onBlur={()=>this.setState({inputSelection: 'none'})}
              />
            </div>
            <div stlye={{width: '96%', height: '45%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
              {!isMobile && <LockRoundedIcon style={{fontSize: 50, color: this.state.inputSelection === 'password' ? '#d63031' : '#2d3436' }}/>}
              <CssTextField
                id="custom-css-standard-input password"
                label="Password"
                type="password"
                style={{width: '85%', marginLeft: !isMobile ? '2%' : '7.5%'}}
                onChange={(e)=>this.setState({passwordInput: e.target.value})}
                onFocus={()=>this.setState({inputSelection: 'password'})}
                onBlur={()=>this.setState({inputSelection: 'none'})}
              />
            </div>
          </div>
          <div className="two-button-container">
            <div className="button" onClick={this.goToWelcome}>
              <h4 style={{color: 'white'}}>BACK</h4>
            </div>
            <div className="red button" onClick={this.login}>
              <h4 style={{color: '#F2F2F2'}}>LOG IN</h4>
            </div>
          </div>
          {this.state.errorMsg.length > 0 &&
            <div className="failure error-message-modal slide-down-snack">
              <CloseRoundedIcon className="hover-small" style={{color: 'white', position: 'absolute', top: 2, right: 2, fontSize: 14}} onClick={()=>this.setState({errorMsg: []})}/>
              <p style={{color: 'white'}}>{this.state.errorMsg[0]}</p>
            </div>
          }
        </div>
      )
    } else if (this.props.authPath === 'register') {
      return(
        <div className={`register Welcome ${this.state.slideOut ? 'slide-out' : null} ${this.state.slideIn ? 'slide-in' : null}`} style={{width: isMobile && '90%'}}>
          <div className="register-title title">
            <h2 style={{color: '#F2F2F2', marginLeft: '2%'}}>Registration</h2>
          </div>
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alginItems: 'center', width: '100%', height: '90%'}}>
            <div stlye={{width: '96%', height: '45%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
              {!isMobile && <PersonOutlineRoundedIcon style={{fontSize: 50, color: this.state.inputSelection === 'newUsername' ? '#d63031' : '#2d3436' }}/>}
              <CssTextField
                id="custom-css-standard-input reg-username"
                label="Username"
                style={{width: '85%', marginLeft: !isMobile ? '2%' : '7.5%'}}
                onChange={(e)=>this.setState({newUsernameInput: e.target.value})}
                onFocus={()=>this.setState({inputSelection: 'newUsername'})}
                onBlur={()=>this.setState({inputSelection: 'none'})}
              />
            </div>
            <div stlye={{width: '96%', height: '45%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
              {!isMobile && <PersonOutlineRoundedIcon style={{fontSize: 50, color: this.state.inputSelection === 'newName' ? '#d63031' : '#2d3436' }}/>}
              <CssTextField
                id="custom-css-standard-input first-name"
                label="First Name"
                style={{width: '85%', marginLeft: !isMobile ? '2%' : '7.5%'}}
                onChange={(e)=>this.setState({newName: e.target.value})}
                onFocus={()=>this.setState({inputSelection: 'newName'})}
                onBlur={()=>this.setState({inputSelection: 'none'})}
              />
            </div>
            <div stlye={{width: '96%', height: '45%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
              {!isMobile && <PersonOutlineRoundedIcon style={{fontSize: 50, color: this.state.inputSelection === 'newLastName' ? '#d63031' : '#2d3436' }}/>}
              <CssTextField
                id="custom-css-standard-input last-name"
                label="Last Name"
                style={{width: '85%', marginLeft: !isMobile ? '2%' : '7.5%'}}
                onChange={(e)=>this.setState({newLastName: e.target.value})}
                onFocus={()=>this.setState({inputSelection: 'newLastName'})}
                onBlur={()=>this.setState({inputSelection: 'none'})}
              />
            </div>
            <div stlye={{width: '96%', height: '45%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
              {!isMobile && <PersonOutlineRoundedIcon style={{fontSize: 50, color: this.state.inputSelection === 'operatorCode' ? '#d63031' : '#2d3436' }}/>}
              <CssTextField
                id="custom-css-standard-input operator-code"
                label="Operator Code"
                style={{width: '85%', marginLeft: !isMobile ? '2%' : '7.5%'}}
                value={this.state.operatorCode}
                onChange={(e)=>this.setState({operatorCode: e.target.value})}
                onFocus={()=>this.setState({inputSelection: 'operatorCode'})}
                onBlur={()=>this.setState({inputSelection: 'none'})}
                disabled={!!(this.state.operatorCode !== "")}
              />
            </div>
            <div stlye={{width: '96%', height: '45%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
              {!isMobile && <LockRoundedIcon style={{fontSize: 50, color: this.state.inputSelection === 'newPassword' ? '#d63031' : '#2d3436' }}/>}
              <CssTextField
                id="custom-css-standard-input reg-password"
                label="Password"
                type="password"
                style={{width: '85%', marginLeft: !isMobile ? '2%' : '7.5%'}}
                onChange={(e)=>this.setState({newPasswordInput: e.target.value})}
                onFocus={()=>this.setState({inputSelection: 'newPassword'})}
                onBlur={()=>this.setState({inputSelection: 'none'})}
              />
            </div>
            <div stlye={{width: '96%', height: '45%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
              {!isMobile && <LockRoundedIcon style={{fontSize: 50, color: this.state.inputSelection === 'passwordConfirmation' ? '#d63031' : '#2d3436' }}/>}
              <CssTextField
                id="custom-css-standard-input reg-password-confirmation"
                label="Confirm Password"
                type="password"
                style={{width: '85%', marginLeft: !isMobile ? '2%' : '7.5%'}}
                onChange={(e)=>this.setState({passwordConfirmation: e.target.value})}
                onFocus={()=>this.setState({inputSelection: 'passwordConfirmation'})}
                onBlur={()=>this.setState({inputSelection: 'none'})}
              />
            </div>
          </div>
          <div className="two-button-container">
            <div className="button" onClick={this.goToWelcome}>
              <h4 style={{color: 'white'}}>BACK</h4>
            </div>
            <div className="red button" onClick={this.register}>
              <h4 style={{color: 'white'}}>CREATE ACCOUNT</h4>
            </div>
          </div>
          {this.state.errorMsg.length > 0 &&
            <div className="registration failure error-message-modal slide-down-snack">
              <CloseRoundedIcon className="hover-small" style={{color: 'white', position: 'absolute', top: 2, right: 2, fontSize: 14}} onClick={()=>this.setState({errorMsg: []})}/>
              <p style={{color: 'white'}}>{this.state.errorMsg[0]}</p>
            </div>
          }
        </div>
      )
    }
  }
}
