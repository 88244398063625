import { SAVE_MARKERS, SAVE_SHAPE, SAVE_DIRECTIONS, SET_TOLL_COST, SET_STATES, SET_TOTAL_DISTANCE, SET_TRAVEL_TIME, SET_WAYPOINTS, SAVE_DATA, CLEAR_REDUCERS, RESTRICTED_CIRCLE, SET_ERROR, ERROR_BOOL } from './types';
import { combineReducers } from 'redux';

const initialErrorState = {
  error: {},
  isError: false
}

function errorReducer (state = initialErrorState, action) {
  switch (action.type) {
    case ERROR_BOOL:
      return {...state,
        isError: action.payload
      }
    case SET_ERROR:
      return {...state,
        error: action.payload
      }
    default:
      return state;
  }
}

const initialCircleState = {
  restrictedCircle: {center: {lat: 0, lng: 0}, radius: 0},
  restrictionPoints: []
}

function circleReducer (state = initialCircleState, action) {
  switch (action.type) {
    case RESTRICTED_CIRCLE:
      return {...state,
        restrictedCircle: action.payload
      }
    default:
      return state;
  }
}

const initialRouteState = {
  newRouteShape: [],
  newRouteMarkers: [],
  saveData: {},
}

function routeReducer (state = initialRouteState, action) {
  switch (action.type) {
    case SAVE_SHAPE:
      return {...state,
        newRouteShape: action.payload
      }
    case SAVE_MARKERS:
      return {...state,
        newRouteMarkers: action.payload
      }
    case SAVE_DATA:
      return {...state,
        saveData: action.payload
      }
    case SET_ERROR:
      return {
        newRouteShape: [],
        newRouteMarkers: [],
        saveData: {}
      }
    case CLEAR_REDUCERS:
      return {
        newRouteShape: [],
        newRouteMarkers: [],
        saveData: {}
      }
    default:
      return state;
  }
}

const initialDirectionState = {
  directions: [],
  tollCost: 0,
  milesByState: [],
  totalDistance: 0,
  travelTime: 0,
  wayPoints: []
}

function directionReducer (state = initialDirectionState, action) {
  switch (action.type) {
    case SAVE_DIRECTIONS:
      return {...state,
        directions: action.payload
      }
    case SET_TOLL_COST:
      return {...state,
        tollCost: action.payload
      }
    case SET_STATES:
      return {...state,
        milesByState: action.payload
      }
    case SET_TOTAL_DISTANCE:
      return {...state,
        totalDistance: action.payload
      }
    case SET_TRAVEL_TIME:
      return {...state,
        travelTime: action.payload
      }
    case SET_WAYPOINTS:
      return {...state,
        wayPoints: action.payload
      }
    case SET_ERROR:
      return {
        directions: [],
        tollCost: 0,
        milesByState: [],
        totalDistance: 0,
        travelTime: 0,
        wayPoints: []
      }
    case CLEAR_REDUCERS:
      return {
        directions: [],
        tollCost: 0,
        milesByState: [],
        totalDistance: 0,
        travelTime: 0,
        wayPoints: []
      }
    default:
      return state;
  }
}

export default combineReducers({
  route: routeReducer,
  direction: directionReducer,
  circle: circleReducer,
  errors: errorReducer
})
