import React from 'react';
import '../App.css';
import { save, load, update, invite } from '../api.js'
//material-ui
import CircularProgress from '@material-ui/core/CircularProgress';
import CloudUploadRoundedIcon from '@material-ui/icons/CloudUploadRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded';
import { TextField } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#d63031',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#d63031',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#d63031',
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '75%',
    marginLeft: '7.5%',
    height: 50
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

var validator = require("email-validator");

function Modal(props) {

  const classes = useStyles();

  const [loadedRoute, setLoadedRoute] = React.useState('');
  const [errorMsg, setErrorMsg] = React.useState('');
  const [successMsg, setSuccessMsg] = React.useState('');

  var routeName = '',
      inviteEmail = '';

  const handleTextChangeSave = (e) => {
    routeName = e.target.value
  }

  const handleTextChangeInvite = (e) => {
    inviteEmail = e.target.value
  }

  const saveNewRoute = () => {
    const axios = require('axios')
    var data = props.saveData;
    data.name = routeName;
    if (data.name.length > 0) {
      save(data, axios).catch(msg => {
        props.handleBadRequest(msg);
      })
      setSuccessMsg('Route saved successfully!')
      setTimeout(()=>props.closeSaveModal(), 2500)
    } else {
      setErrorMsg('Enter a valid route name')
    }
  }

  const chooseRouteToLoad = (event) => {
    setLoadedRoute(event.target.value)
  }

  const loadRoute = () => {
    props.startLoad();
    const axios = require('axios')
    var data = {'api_key': props.apiKey};
    update(data, axios, props.apiKey)
    load(loadedRoute, axios).then(data => {
      props.loadedRouteData(data)
    }).catch(msg => {
      props.handleBadRequest()
    })
  }

  const sendInvite = () => {
    var data = {'api_key': props.apiKey};
    const axios = require('axios');
    if (inviteEmail.length > 0 && validator.validate(inviteEmail)) {
      update(data, axios, props.apiKey)
      .then((res) => {
        const payload = {
          inviting_user: res.id,
          email: inviteEmail,
          api_key: props.apiKey,
          url: `${window.location.href}register`
        }

        invite(payload, axios).catch(msg => {
          props.handleBadRequest(msg)
        })
        setSuccessMsg('Invitation sent successfully!')
        setTimeout(()=>closeInvite(), 2500)
      })
    } else {
      setErrorMsg('Enter a valid email address')
    }
  }

  const closeInvite = () => {
    props.closeSaveModal()
    props.handleInviteClick();
  }

  return (
    <div className="Modal-Container">
      <div className={props.action === 'loading' ? null : "Welcome slide-in"}>
        {props.action === 'save' ?
          <div style={{height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <div className="title" style={{justifyContent: 'space-between'}}>
              <h2 style={{color: '#F2F2F2', marginLeft: 10}}>Save this route</h2>
              <CloseRoundedIcon className="hover" style={{fontSize: 40, color: 'white', marginRight: 10}} onClick={props.closeSaveModal}/>
            </div>
            <div stlye={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
              <CloudUploadRoundedIcon style={{fontSize: 50, color: '#d63031', marginLeft: 10}}/>
              <CssTextField
                id="custom-css-standard-input"
                label="Route Name"
                style={{width: '75%', marginLeft: '7.5%'}}
                onChange={handleTextChangeSave}
              />
            </div>
            <div className="two-button-container">
              <div>
              </div>
              <div className="red button" onClick={saveNewRoute}>
                <h4 style={{color: '#F2F2F2'}}>SAVE</h4>
              </div>
            </div>
          </div>
        :
        props.action === 'loading' ?
          <div style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <CircularProgress style={{color: '#d63031'}} size={120} />
          </div>
        :
        props.action === 'load' ?
          <div style={{height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <div className="title" style={{justifyContent: 'space-between'}}>
              <h2 style={{color: '#F2F2F2', marginLeft: 10}}>Load An Existing Route</h2>
              <CloseRoundedIcon className="hover" style={{fontSize: 40, color: 'white', marginRight: 10}} onClick={props.closeSaveModal}/>
            </div>
            <div stlye={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <CloudDownloadRoundedIcon style={{fontSize: 50, color: '#d63031', marginLeft: 10}}/>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Select an existing route</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={loadedRoute}
                  onChange={chooseRouteToLoad}
                >
                  {props.savedRoutes.map(route => {
                      return <MenuItem key={route.value} value={route.value}>{route.text}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="two-button-container">
              <div>
              </div>
              <div className="red button" onClick={loadRoute}>
                <h4 style={{color: '#F2F2F2'}}>LOAD</h4>
              </div>
            </div>
          </div>
        :
        props.action === 'invite' ?
          <div style={{height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <div className="title" style={{justifyContent: 'space-between'}}>
              <h2 style={{color: '#F2F2F2', marginLeft: 10}}>Invite User</h2>
              <CloseRoundedIcon className="hover" style={{fontSize: 40, color: 'white', marginRight: 10}} onClick={closeInvite}/>
            </div>
            <div stlye={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
              <CloudUploadRoundedIcon style={{fontSize: 50, color: '#d63031', marginLeft: 10}}/>
              <CssTextField
                id="custom-css-standard-input"
                label="User's email"
                style={{width: '75%', marginLeft: '7.5%'}}
                onChange={handleTextChangeInvite}
              />
            </div>
            <div className="two-button-container">
              <div>
              </div>
              <div className="red button" onClick={sendInvite}>
                <h4 style={{color: '#F2F2F2'}}>INVITE</h4>
              </div>
            </div>
          </div>
        :
          null
        }
        {errorMsg.length > 0 &&
          <div className="failure error-message-modal slide-down-snack">
            <CloseRoundedIcon className="hover-small" style={{color: 'white', position: 'absolute', top: 2, right: 2, fontSize: 14}} onClick={()=>setErrorMsg('')}/>
            <p style={{color: 'white'}}>{errorMsg}</p>
          </div>
        }
        {successMsg.length > 0 &&
          <div className="success error-message-modal slide-down-snack">
            <CloseRoundedIcon className="hover-small" style={{color: 'white', position: 'absolute', top: 2, right: 2, fontSize: 14}} onClick={()=>setErrorMsg('')}/>
            <p style={{color: 'white'}}>{successMsg}</p>
          </div>
        }
      </div>
    </div>
  );
}

export default Modal;
