export const SAVE_SHAPE = "SAVE_SHAPE";
export const SAVE_MARKERS = "SAVE_MARKERS";
export const SAVE_DIRECTIONS= "SAVE_DIRECTIONS";
export const SET_TOLL_COST= "SET_TOLL_COST";
export const SET_STATES= "SET_STATES";
export const SET_TOTAL_DISTANCE= "SET_TOTAL_DISTANCE";
export const SET_TRAVEL_TIME= "SET_TRAVEL_TIME";
export const SET_WAYPOINTS = "SET_WAYPOINTS";
export const SAVE_DATA = "SAVE_DATA";
export const CLEAR_REDUCERS = "CLEAR_REDUCERS";
export const RESTRICTED_CIRCLE = "RESTRICTED_CIRCLE";
export const SET_ERROR = "SET_ERROR";
export const ERROR_BOOL = "ERROR_BOOL";
