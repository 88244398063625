import React from 'react';
import '../App.css';


function Directions(props) {

  const renderDirections = () => {
    return props.directions.map((action, index) => {
      var editedAction = action.replace(/<[^>]*>/g, "")
      return (
        <li key={"direction " + index}>{editedAction}</li>
      )
    })
  }

  const renderStateMiles = () => {
    return props.milesByState.map(item => {
      return (<li key={item.state}><i>{item.state}</i>: {item.distance} mi.</li>)
    })
  }

  const renderRouteTitle = () => {
    return props.wayPoints.map((waypoint, index) => {
      return ( <h3 key={"title " + index}><strong>{index !== 0 && " - "} {waypoint.label} </strong></h3> )
    })
  }

  return(
    <div className="Directions-Component">
      <div className='top-bar'>
        <h3 style={{color: 'white', marginLeft: '2.5%'}}>Directions</h3>
      </div>
      <div style={{padding: 10}}>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        {renderRouteTitle()}
      </div>
        <ol style={{lineHeight: 1.1}}>
          {renderDirections()}
        </ol>
        <p><strong>Total Distance: </strong>{props.totalDistance} mi</p>
        <p><strong>Travel Time: </strong>{props.travelTime} minutes (in current traffic)</p>
        <p><strong>Toll Cost: </strong>${props.tollCost}</p>
        <p><strong>State Mileage: </strong></p>
        <ul>
          {renderStateMiles()}
        </ul>
      </div>
    </div>
  )
}

export default Directions;
