import React from 'react';
import '../App.css';
import { Marker } from 'busie-here-maps-react';
import Geocode from 'react-geocode';

import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';

export default function StaticMarkers(props) {


  React.useEffect(()=>{
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_PLACES_API_KEY);
  }, [])

  const renderMarkers = (markers) => {
    return markers.map((marker, index) => {
      return (
        <Marker
          draggable={false}
          lat={marker.lat}
          lng={marker.lng}
          key={"marker " + index}
        >
          <div style={{position: 'absolute', height: 20, width: 20, top: -10, left: -10, zIndex: index}}>
            <FiberManualRecordRoundedIcon className="hover-marker" style={{fontSize: 20, color: '#ff7675', zIndex: index+1}} />
          </div>
        </Marker>
      )
    })
  }

  const markers = props.markers;

  return(
    <div style={{zIndex: 990, position: 'absolute'}}>
        {renderMarkers(markers)}
    </div>
  )
}
