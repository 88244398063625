import React from 'react';
import '../App.css';
import { Marker } from 'busie-here-maps-react';
import Geocode from 'react-geocode';

import SignalWifi4BarRoundedIcon from '@material-ui/icons/SignalWifi4BarRounded';
import FlagRoundedIcon from '@material-ui/icons/FlagRounded';

export default function StopsMarkers(props) {


  React.useEffect(()=>{
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_PLACES_API_KEY);
  }, [])

  const renderMarkers = (markers) => {
    return markers.map((marker, index) => {
      return (
        <Marker
          draggable={false}
          lat={marker.lat}
          lng={marker.lng}
          key={"marker " + index}
        >
        {index === 0 ?
          <div style={{position: 'absolute', height: 30, width: 30, top: -30, left: 0}}>
            <FlagRoundedIcon style={{fontSize: 30, color: '#00b894'}} />
          </div>
        :
        index === markers.length - 1 ?
          <div style={{position: 'absolute', height: 30, width: 30, top: -30, left: 0}}>
            <FlagRoundedIcon style={{fontSize: 30, color: '#0984e3'}} />
          </div>
        :
          <div style={{position: 'absolute', height: 30, width: 30, top: -30, left: -15, zIndex:997, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <SignalWifi4BarRoundedIcon style={{fontSize: 30, color: 'white', zIndex:998}} />
            <h5 style={{color: '#d63031', zIndex: 999, marginLeft: -19}}>{index}</h5>
          </div>
        }
        </Marker>
      )
    })
  }

  const markers = props.points.stops;

  return(
    <div style={{zIndex: 995, position: 'absolute'}}>
        {renderMarkers(markers)}
    </div>
  )
}
