/*jshint esversion:6*/


const route = function(data, axios){
  var url = `${process.env.REACT_APP_BUSIE_API_URL}/api/routes/`,
  message;
  return new Promise(function(resolve, reject) {
    axios.post(url, data).then(function(response) {
      resolve(response.data);
    }).catch(function(error) {
      if (error.response) {
        message = {
          errorType: '' + error.response.status + ' ' + error.response.statusText + ':',
          errorMessage: error.response.data.includes("<!DOCTYPE html>") ? " " : error.response.data
        }
      } else if (error.request) {
        message = {
          errorType: '' + error.request.status + ' ' + error.request.statusText + ':',
          errorMessage: error.request.data.includes("<!DOCTYPE html>") ? " " : error.request.data
        }
      } else {
        message = {
          errorType: 'Error: ',
          errorMessage: error.message.includes("<!DOCTYPE html>") ? " " : error.message
        }
      }
      reject(message);
    });
  });
};

const load = function(routeId, axios) {
  var url = `${process.env.REACT_APP_BUSIE_API_URL}/api/routes/saved/${routeId}/`,
  message;
  return new Promise(function(resolve, reject) {
    axios.get(url).then(function(response) {
      resolve(response.data);
    }).catch(function(error) {
      if (error.response) {
        message = {
          errorType: '' + error.response.status + ' ' + error.response.statusText + ':',
          errorMessage: error.response.data.includes("<!DOCTYPE html>") ? " " : error.response.data
        }
      } else if (error.request) {
        message = {
          errorType: '' + error.request.status + ' ' + error.request.statusText + ':',
          errorMessage: error.request.data.includes("<!DOCTYPE html>") ? " " : error.request.data
        }
      } else {
        message = {
          errorType: 'Error: ',
          errorMessage: error.message.includes("<!DOCTYPE html>") ? " " : error.message
        }
      }
      reject(message);
    });
  });
};

const getSavedRoutes = function(axios, api_key) {
  var url = `${process.env.REACT_APP_BUSIE_API_URL}/api/routes/saved/${api_key}/`,
  message;
  return new Promise(function(resolve, reject) {
    axios.get(url).then(function(response) {
      resolve(response.data);
    }).catch(function(error) {
      if (error.response) {
        message = {
          errorType: '' + error.response.status + ' ' + error.response.statusText + ':',
          errorMessage: error.response.data.includes("<!DOCTYPE html>") ? " " : error.response.data
        }
      } else if (error.request) {
        message = {
          errorType: '' + error.request.status + ' ' + error.request.statusText + ':',
          errorMessage: error.request.data.includes("<!DOCTYPE html>") ? " " : error.request.data
        }
      } else {
        message = {
          errorType: 'Error: ',
          errorMessage: error.message.includes("<!DOCTYPE html>") ? " " : error.message
        }
      }
      reject(message);
    });
  });
};

const save = function(data, axios) {
  var url = `${process.env.REACT_APP_BUSIE_API_URL}/api/routes/saved//`,
  message;
  return new Promise(function(resolve, reject) {
    axios.post(url, data).then(function(response) {
      resolve(response.data);
    }).catch(function(error) {
      if (error.response) {
        message = {
          errorType: '' + error.response.status + ' ' + error.response.statusText + ':',
          errorMessage: error.response.data.includes("<!DOCTYPE html>") ? " " : error.response.data
        }
      } else if (error.request) {
        message = {
          errorType: '' + error.request.status + ' ' + error.request.statusText + ':',
          errorMessage: error.request.data.includes("<!DOCTYPE html>") ? " " : error.request.data
        }
      } else {
        message = {
          errorType: 'Error: ',
          errorMessage: error.message.includes("<!DOCTYPE html>") ? " " : error.message
        }
      }
      reject(message);
    });
  });
};

const report = function(data, axios) {
  var url = `${process.env.REACT_APP_BUSIE_API_URL}/api/restricted/`,
  message;
  return new Promise(function(resolve, reject) {
    axios.post(url, data).then(function(response) {
      resolve(response.data);
    }).catch(function(error) {
      if (error.response) {
        message = {
          errorType: '' + error.response.status + ' ' + error.response.statusText + ':',
          errorMessage: error.response.data.includes("<!DOCTYPE html>") ? " " : error.response.data
        }
      } else if (error.request) {
        message = {
          errorType: '' + error.request.status + ' ' + error.request.statusText + ':',
          errorMessage: error.request.data.includes("<!DOCTYPE html>") ? " " : error.request.data
        }
      } else {
        message = {
          errorType: 'Error: ',
          errorMessage: error.message.includes("<!DOCTYPE html>") ? " " : error.message
        }
      }
      reject(message);
    });
  });
};

const update = function(data, axios, apiKey) {
  var url = `${process.env.REACT_APP_BUSIE_API_URL}/api/operators/${apiKey}/`,
  message;
  return new Promise(function(resolve, reject) {
    axios.patch(url, data).then(function(response) {
      resolve(response.data);
    }).catch(function(error) {
      if (error.response) {
        message = {
          errorType: '' + error.response.status + ' ' + error.response.statusText + ':',
          errorMessage: error.response.data.includes("<!DOCTYPE html>") ? " " : error.response.data
        }
      } else if (error.request) {
        message = {
          errorType: '' + error.request.status + ' ' + error.request.statusText + ':',
          errorMessage: error.request.data.includes("<!DOCTYPE html>") ? " " : error.request.data
        }
      } else {
        message = {
          errorType: 'Error: ',
          errorMessage: error.message.includes("<!DOCTYPE html>") ? " " : error.message
        }
      }
      reject(message);
    });
  });
};

const invite = function(data, axios) {
  var url = `${process.env.REACT_APP_BUSIE_API_URL}/api/invite/`,
  message;
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(response => {
      resolve(response.data)
    }).catch(error => {
      if (error.response) {
        message = {
          errorType: '' + error.response.status + ' ' + error.response.statusText + ':',
          errorMessage: error.response.data.includes("<!DOCTYPE html>") ? " " : error.response.data
        }
      } else if (error.request) {
        message = {
          errorType: '' + error.request.status + ' ' + error.request.statusText + ':',
          errorMessage: error.request.data.includes("<!DOCTYPE html>") ? " " : error.request.data
        }
      } else {
        message = {
          errorType: 'Error: ',
          errorMessage: error.message.includes("<!DOCTYPE html>") ? " " : error.message
        }
      }
      reject(message);
    })
  })
}

const login = function(axios, apiKey) {
  var url = `${process.env.REACT_APP_BUSIE_API_URL}/api/operators/${apiKey}/`,
  message;
  return new Promise(function(resolve, reject) {
    axios.get(url).then(function(response) {
      resolve(response.data);
    }).catch(function(error) {
      if (error.response) {
        message = {
          errorType: '' + error.response.status + ' ' + error.response.statusText + ':',
          errorMessage: error.response.data.includes("<!DOCTYPE html>") ? " " : error.response.data
        }
      } else if (error.request) {
        message = {
          errorType: '' + error.request.status + ' ' + error.request.statusText + ':',
          errorMessage: error.request.data.includes("<!DOCTYPE html>") ? " " : error.request.data
        }
      } else {
        message = {
          errorType: 'Error: ',
          errorMessage: error.message.includes("<!DOCTYPE html>") ? " " : error.message
        }
      }
      reject(message);
    });
  });
};

export {route, load, getSavedRoutes, save, report, update, login, invite};
