import React from 'react';
import '../App.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PlacesAutocomplete from './PlacesAutocomplete';

//icons
import Brightness1RoundedIcon from '@material-ui/icons/Brightness1Rounded';
import SaveAltRoundedIcon from '@material-ui/icons/SaveAltRounded';
import PrintRoundedIcon from '@material-ui/icons/PrintRounded';
import FlagRoundedIcon from '@material-ui/icons/FlagRounded';
import ArrowLeftRoundedIcon from '@material-ui/icons/ArrowLeftRounded';
import ArrowRightRoundedIcon from '@material-ui/icons/ArrowRightRounded';
import ArrowDropUpRoundedIcon from '@material-ui/icons/ArrowDropUpRounded';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';

const getItemStyle = (index, length, isDragging, draggableStyle) => ({
  height: 40,
  width: '96%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 5,
  padding: 2,
  borderRadius: 5,
  // change background colour if dragging
  background: isDragging ? "#ff7675" : "#d63031",

  // styles we need to apply on draggables
  ...draggableStyle
});

export default class RouteModalDnD extends React.Component {

  state = {
    items: {},
    expanded: true,
    slideDown: false,
    slideUp: false,
    prepForPrint: false,
    locInput: '',
    showing: true
  }

  hideModal = () => {
    this.setState({slideDown: true, slideUp: false})
    setTimeout(()=>this.setState({expanded: false}), 500)
  }

  expandModal = () => {
    this.setState({slideDown: false, slideUp: true})
    setTimeout(()=>this.setState({expanded: true, slideUp: false}), 500)
  }

  toggleShowing = () => {
    this.setState({showing: false})
    setTimeout(()=>this.setState({showing: true}), 10)
  }

  componentDidMount(){
    this.setState({items: this.props.points})
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.points.stops.length !== this.props.points.stops.length) {
      this.setState({items: this.props.points});
    }
  }

  handlePrint = async() => {
    await this.setState({prepForPrint: true})
    window.print();
    setTimeout(()=>this.setState({prepForPrint: false}), 2000)
  }

  handleSave = () => {
    let arr = this.props.points.stops > this.props.points.totalPoints ? this.props.points.stops : this.props.points.totalPoints;
    const data = {
      additional: arr.length > 2 ? JSON.stringify(arr.slice(1, arr.length-1)) : "[]",
      api_key: this.props.apiKey,
      dest: arr.slice(-1)[0],
      start: arr.slice(0,1)[0],
      route: this.props.routeId,
      state_mileage: this.props.state_mileage,
      toll_cost: this.props.tollCost,
    }
    this.props.setSaveData(data)
    this.props.openSaveModal();
  }


  render() {
    return (
      <div className={`Map-Modal ${this.state.slideDown && 'slide-down'} ${this.state.slideUp && 'slide-up'} ${this.state.prepForPrint && 'invisible'}`}>
        <div className={`top-bar route ${this.state.slideDown && 'pop-out'}`}>
          <h3 style={{color: 'white'}}>Request a Route</h3>
          <div>
            {this.state.expanded ? <ArrowLeftRoundedIcon className="hover-small" style={{fontSize: 40, color: 'white'}} onClick={this.hideModal}/> : <ArrowRightRoundedIcon className="hover" style={{fontSize: 40, color: 'white'}} onClick={this.expandModal} />}
          </div>
        </div>
        <div className="route-modal">
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', overflowY: 'scroll'}}>
            <PlacesAutocomplete
              shape={this.props.shape}
              addPointToRoute={this.props.addPointToRoute}
              showing={this.state.showing}
              toggleShowing={this.toggleShowing}
              editingEnabled={this.props.editingEnabled}
            />
            <img
              src={require('../images/powered_by_google.png')}
              alt="Powered By Google"
              style={{width: '25%', marginLeft: '74%', marginTop: 5}}
            />
            {this.props.points.stops.length > 0 &&
              <DragDropContext onDragEnd={this.props.shape.length === 0 || this.props.editingEnabled ? this.props.onDragEnd : null}>
                <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ overflowY: 'hidden', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', marginTop: 10}}
                  >
                  {this.props.points.stops.map((point, index) => (
                    <Draggable key={"item " + index} draggableId={"item " + index} index={index} isDragDisabled={(!!(this.props.shape.length !== 0) && !this.props.editingEnabled) || this.state.slideDown}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            index,
                            this.props.points.stops.length-1,
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          {index === 0 ?
                            <div>
                              <FlagRoundedIcon style={{fontSize: 25, color: '#00b894'}}/>
                            </div>
                          :
                            index === this.props.points.stops.length -1 ?
                            <div>
                              <FlagRoundedIcon style={{fontSize: 25, color: '#0984e3'}}/>
                            </div>
                          :
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                              <Brightness1RoundedIcon style={{fontSize: 30, color: 'white'}} />
                              <h5 style={{color: '#d63031', marginLeft: -18, zIndex: 999}}>{index}</h5>
                            </div>
                          }
                          <div style={{width: 240, overflowX: 'hidden', whiteSpace: 'nowrap'}}>
                            <h5 style={{color: 'white', textOverflow: 'ellipsis', textAlign: 'left'}}>{point.loc.split(",")[0]}, {point.loc.split(",")[1]}, {point.loc.split(",")[2].split(" ")[1]}</h5>
                          </div>
                            <div style={{display: 'flex', flexDirection: 'row', width: 60, alignItems: 'center', justifyContent: 'space-around'}}>
                              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                {index !== 0 && <ArrowDropUpRoundedIcon className={this.props.shape.length === 0 || this.props.editingEnabled ? "hover-small" : null} style={{fontSize: 40}} onClick={this.props.shape.length === 0 || this.props.editingEnabled ? ()=>this.props.moveStopDown(index) : null}/>}
                                {index !== this.props.points.stops.length-1 && <ArrowDropDownRoundedIcon className={this.props.shape.length === 0 || this.props.editingEnabled ? "hover-small" : null} style={{fontSize: 40, marginTop: index !== 0 && -20}} onClick={this.props.shape.length === 0 || this.props.editingEnabled ? ()=>this.props.moveStopUp(index) : null}/>}
                            </div>
                            <HighlightOffRoundedIcon className={this.props.shape.length === 0 || this.props.editingEnabled ? "hover" : null} style={{fontSize: 20, color: this.props.shape.length === 0 || this.props.editingEnabled ? 'white' : '#2d3436'}} onClick={this.props.shape.length === 0 || this.props.editingEnabled ? ()=>this.props.removeStop(index) : null}/>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  </div>
                )}
                </Droppable>
              </DragDropContext>
            }
          </div>
          <div style={{marginBottom: 10}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', width: '90%'}}>
              {this.props.shape.length === 0 &&
                <div
                  className={this.props.points.totalPoints.length > 1 || this.props.points.stops.length > 1 ? "hover" : null}
                  style={{marginLeft: 30, marginTop: 15, width: '50%', height: 35, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: '#d63031', borderRadius: 5}}
                  onClick={this.props.points.totalPoints.length > 1 || this.props.points.stops.length > 1 ? ()=>this.props.handleNewRoute(this.props.points, false, "new") : null}
                >
                  <h5 style={{color: 'white'}}>{this.props.points.totalPoints.length === 0 && this.props.points.stops.length === 0 ? 'NEW ROUTE' : 'ONE-WAY ROUTE'}</h5>
                </div>
              }
              {this.props.shape.length === 0 &&
                this.props.points.totalPoints.length === 0 && this.props.points.stops.length === 0 ?
                <div
                  className="hover"
                  style={{marginLeft: 30, marginTop: 15, width: '50%', height: 35, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: '#dfe6e9', borderRadius: 5}}
                  onClick={this.props.openLoadModal}
                >
                  <h5 style={{color: '#2d3436'}}>LOAD SAVED ROUTE</h5>
                </div>
              : this.props.shape.length === 0 ?
                <div
                  className={this.props.points.totalPoints.length > 1 || this.props.points.stops.length > 1 ? "hover" : null}
                  style={{marginLeft: 30, marginTop: 15, width: '50%', height: 35, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: '#d63031', borderRadius: 5}}
                  onClick={this.props.points.totalPoints.length > 1 || this.props.points.stops.length > 1 ? ()=>this.props.handleNewRoute(this.props.points, true, "new") : null}
                >
                  <h5 style={{color: 'white'}}>ROUND-TRIP ROUTE</h5>
                </div>
              :
                <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-around', alignItems: 'center'}}>
                  <div
                    className="hover"
                    style={{marginLeft: 30, marginTop: 15, width: '50%', height: 35, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: this.props.editingEnabled ? '#00b894' : '#d63031', borderRadius: 5}}
                    onClick={this.props.enableEditing}
                  >
                    <h5 style={{color: 'white'}}>{this.props.editingEnabled ? 'EDITING' : 'EDIT ROUTE'}</h5>
                  </div>
                  <div
                    className="hover"
                    style={{marginLeft: 30, marginTop: 15, width: '50%', height: 35, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: '#d63031', borderRadius: 5}}
                    onClick={this.props.clearPathfinder}
                  >
                    <h5 style={{color: 'white'}}>CLEAR PATHFINDER</h5>
                  </div>
                </div>
              }
            </div>
            {this.props.routeLoaded && this.props.editingEnabled ?
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', width: '90%'}}>
                <div
                  className="hover"
                  style={{marginLeft: 30, marginTop: 15, width: '50%', height: 35, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: '#d63031', borderRadius: 5}}
                  onClick={()=>this.props.handleNewRoute(this.props.points, false, "edit")}
                >
                  <h5 style={{color: 'white'}}>ONE-WAY ROUTE</h5>
                </div>
                <div
                  className="hover"
                  style={{marginLeft: 30, marginTop: 15, width: '50%', height: 35, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: '#d63031', borderRadius: 5}}
                  onClick={()=>this.props.handleNewRoute(this.props.points, true, "edit")}
                >
                  <h5 style={{color: 'white'}}>ROUND-TRIP ROUTE</h5>
                </div>
              </div>
            :
              this.props.routeLoaded &&  !this.props.editingEnabled &&
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', width: '90%'}}>
                <div className="hover" style={{marginLeft: 30, marginTop: 15, width: '40%', height: 35, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: '#dfe6e9', borderRadius: 5}} onClick={this.handleSave}>
                  <SaveAltRoundedIcon style={{fontSize: 30, color: '#2d3436'}} />
                  <h5 style={{color: '#2d3436', marginLeft: 5}}>SAVE</h5>
                </div>
                <div className="hover" style={{marginLeft: 30, marginTop: 15, width: '40%', height: 35, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: '#dfe6e9', borderRadius: 5}} onClick={this.handlePrint}>
                  <PrintRoundedIcon style={{fontSize: 30, color: '#2d3436'}} />
                  <h5 style={{color: '#2d3436', marginLeft: 5}}>PRINT</h5>
                </div>
              </div>
            }
            {this.props.routeLoaded &&
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', width: '90%'}}>
                <div className="hover" style={{marginLeft: 30, marginTop: 15, width: '90%', height: 35, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: '#dfe6e9', borderRadius: 5}} onClick={this.props.createRestrictionBool}>
                  <FlagRoundedIcon style={{fontSize: 30, color: '#2d3436'}} />
                  <h5 style={{color: '#2d3436', marginLeft: 5}}>REPORT RESTRICTED AREA</h5>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}
