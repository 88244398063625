import React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Geocode from 'react-geocode';

import RoomRoundedIcon from '@material-ui/icons/RoomRounded';

function PlacesAutocomplete(props){

  const handleLocSelect = (loc) => {
    Geocode.fromAddress(loc.description)
    .then(res => {
      props.addPointToRoute(loc.description, res.results[0].geometry.location.lat, res.results[0].geometry.location.lng);
      props.toggleShowing();
    },
    error => {
      console.error(error);
    })
  }

  React.useEffect(()=>{
      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_PLACES_API_KEY);
  }, [])

  return (
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10}}>
      <div style={{width: 50, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <RoomRoundedIcon style={{fontSize: 40, color: 'white'}} />
      </div>
      {props.showing && <GooglePlacesAutocomplete
        disabled={!!(props.shape.length !== 0) && !props.editingEnabled}
        inputStyle={{
          width: 280,
          height: 30,
          border: 'none',
          borderBottom: '2px solid white',
          backgroundColor: '#dfe6e9',
          color: '#636e72',
          fontSize: 16,
          fontFamily: 'Roboto',
          padding: 8,
          borderTopRightRadius: 5,
          borderTopLeftRadius: 5,
        }}
        suggestionsStyles={{
          container: {
            position: 'absolute',
            height: 90,
            backgroundColor: '#636e72',
            overflowX: 'scroll',
            padding: 8,
            lineHeight: 1.2
        },
          suggestion: {
            color: '#dfe6e9',
            fontSize: 16,
            cursor: 'pointer'
          }
        }}
        onSelect={(loc)=>{
          handleLocSelect(loc)
        }}
      />}
    </div>
  )
}

export default PlacesAutocomplete;
